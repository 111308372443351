





























import Vue from 'vue';
import ImagePreview from "./ImagePreview.vue";
import bytes from "bytes";
import isImage from "./helpers/isImage";
import LoadingBar from "./LoadingBar.vue";
import removeFileFromGFUploadedMeta from "./helpers/deleteFileFromHiddenGFInput";
import DragHandle from "./DragHandle.vue";

export default Vue.extend({
	name: "File",
	props: [
		'file',
		'formId',
		'fieldId',
		'up',
		'enableCrop',
		'enableSorting',
		'cropRequired',
		'onlyImagesAllowed',
	],
	data: function () {
		return {
			/**
			 * Flag for whether or not a file has already been uploaded and this is a subsequent visit/reload.
			 *
			 * This is required so we can bypass the CSS animation.
			 */
			existingFile: false,
		}
	},
	beforeMount() {
		/**
		 * If this component is mounted and the file percent is immediately 100%, we know that it's an existing file.
		 */
		if (this.file.percent === 100) {
			this.existingFile = true;
		}
	},
	components: {
		LoadingBar,
		ImagePreview,
		DragHandle,
	},
	computed: {
		/**
		 * Allow sorting immediately if the file is not an image or if the image has been processed or rehydrated.
		 *
		 * Sorting is not allowed while the image is uploading or hasn't been processed as there are a lot of moving
		 * parts during processing such as replacing files which is asking for trouble if sorting is thrown into the mix.
		 */
		allowSorting: function () {
			return !isImage(this.file) ||
				(
					this.$store.state.imgPreviews[this.file.id] &&
					(
						!(this.cropRequired && !this.file.cropped) && this.file.processed
						|| this.file.rehydrated
					)
				);
		},
		fileExtension: function() {
			return this.file.name?.match(/(.*?)\.([a-zA-Z0-9]+)$/)?.[2];
		}
	},
	methods: {
		getSize: function (file: MOxieFile): string {
			return bytes(file.size);
		},
		isImage: function (file: MOxieFile): boolean {
			return isImage(file);
		},
		getFileName: function (file: MOxieFile): string {
			/**
			 * This filter allows to modify the file name markup.
			 *
			 * @param {string}     fileName  The file name.
			 * @param {number}     formId    The form ID.
			 * @param {number}     fieldId   The field ID.
			 * @param {MOxieFile}  file      The file object.
			 *
			 * @since 1.5.5
			 */
			let fileName = window.gform.applyFilters('gpfup_filename_markup', file.name, this.formId, this.fieldId, file);

			return fileName;
		},
		deleteFile: function (file: MOxieFile, event: Event): void {
			try {
				removeFileFromGFUploadedMeta(this.formId, this.fieldId, file);
				this.up.removeFile(file.id);
			} catch (e) {
				console.error('Error deleting file.', e);
			}
		},
	}
});
